import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import PatientDetails from "../components/patient/PatientDetails";
import PreviousReports from "../components/report/PreviousReports";
import { AuthorisedMicroReport, ICaseData } from "../types/case";

export const TEST_ID_READ_ONLY_MODE = "ReadOnlyMode";
export const TEST_ID_READ_ONLY_MODE_MICROCOPY = "ReadOnlyModeMicrocopy";

interface ReadOnlyModeProps {
  caseData?: ICaseData;
  authorisedReports: AuthorisedMicroReport[];
}

const ReadOnlyMode = ({
  caseData,
  authorisedReports,
}: ReadOnlyModeProps): JSX.Element => {
  const hasMicroAmendment = authorisedReports.length > 1;

  return (
    <div className="columns is-desktop" data-testid={TEST_ID_READ_ONLY_MODE}>
      <div
        className="column is-two-fifths-desktop"
        data-testid={TEST_ID_READ_ONLY_MODE_MICROCOPY}
      >
        <section className="content">
          <h3 className="title is-4">
            <FontAwesomeIcon className="mr-2" icon={faCheck} />
            This {hasMicroAmendment ? "amendment" : "report"} has been authorised
          </h3>
          <p>
            For subsequent amendments, please contact our clinical and lab teams via{" "}
            <a href="mailto:support@cytedhealth.com">support@cytedhealth.com</a>.
          </p>
          <p>
            To report on another case, please{" "}
            <a href={import.meta.env.VITE_APP_LIMS_URL + "/Home/Index"}>go to the LIMS</a>
            .
          </p>
        </section>
      </div>
      <div className="column is-three-fifths-desktop">
        {!!caseData && <PatientDetails caseData={caseData} />}
        <PreviousReports authorisedReports={authorisedReports} />
      </div>
    </div>
  );
};

export default ReadOnlyMode;
