import React from "react";

import { ICaseData, RequestFormType } from "../../types/case";

export const TEST_ID_REFLUX_HISTORY_SECTION = "RefluxHistorySection";
export const TEST_ID_REFLUX_HISTORY_HEADING = "RefluxHistoryHeading";

interface RefluxHistoryProps {
  caseData: ICaseData;
}

const RefluxHistory = ({ caseData }: RefluxHistoryProps): JSX.Element | null => {
  const {
    requestFormType,
    hasRefluxSymptoms,
    isTakingPPI,
    hasEoEDiagnosis,
    smokingHistory,
  } = caseData;

  if (requestFormType !== RequestFormType.REFLUX) return null;

  return (
    <section data-testid={TEST_ID_REFLUX_HISTORY_SECTION}>
      <h4 className="title is-6 mb-4" data-testid={TEST_ID_REFLUX_HISTORY_HEADING}>
        Reflux history
      </h4>
      <dl>
        <div className="cy-data-list__item">
          <dt>Current heartburn/reflux symptoms?</dt>
          <dd>{hasRefluxSymptoms}</dd>
        </div>
        <div className="cy-data-list__item">
          <dt>Is the patient taking PPI?</dt>
          <dd>{isTakingPPI}</dd>
        </div>
        <div className="cy-data-list__item">
          <dt>Is there an EoE diagnosis?</dt>
          <dd>{hasEoEDiagnosis}</dd>
        </div>
        <div className="cy-data-list__item">
          <dt>Smoking history</dt>
          <dd>{smokingHistory}</dd>
        </div>
      </dl>
    </section>
  );
};

export default RefluxHistory;
